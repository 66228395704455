/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

class AddToCartMembershipLevelSelect {

    public constructor(
        private membershipLevelSelect: JQuery,
        private promotionChoicesContainer: JQuery) {
    }

    public init(): void {

        this.membershipLevelSelect.change(() => {
            this.onChange();
        });

        this.onChange();
    }

    private onChange(): void {
        this.togglePromotionChoicesVisibility();
    }

    private togglePromotionChoicesVisibility() {

        let allowedChoices: string[] = this.getAllowedPromotionChoices();
        this.getPromotionChoiceOptionsOrRadios().each((_index: number, elem: Element) => {
            let element: JQuery = $(elem);
            // Hide the promotion choices that don't make sense 
            // for the currently selected membership level
            this.togglePromotionChoiceVisibility(element, $.inArray(element.val(), allowedChoices) !== -1);
        });

        this.resetPromotionChoiceSelection();
    }

    private togglePromotionChoiceVisibility(choiceElement: JQuery, show: boolean): void {
        // choiceElement should be [type="radio"] or an <option>.
        // If we're dealing with a radio then the parent should be a <label>.
        // If we're dealing with an <option> then the parent should be a <select>.

        if (show) {
            choiceElement.removeClass('hidden');
            choiceElement.parent('label, select').removeClass('hidden');
            this.promotionChoicesContainer.find('.product-choice-label').removeClass('hidden');
        }
        else {
            choiceElement.addClass('hidden');

            if (choiceElement.is('[type="radio"]')) {
                // If this is a radio then hide the label.                
                choiceElement.parent('label').addClass('hidden');
            }
            if (this.promotionChoicesContainer.find('option:not(.hidden), input[type="radio"]:not(.hidden)').length === 0) {
                // No more visible choices
                choiceElement.parent('select').addClass('hidden');
                this.promotionChoicesContainer.find('.product-choice-label').addClass('hidden');
            }
        }
    }

    private resetPromotionChoiceSelection() {
        // Reset the selection to the first visible choice

        let promotionChoiceOptions: JQuery = this.getPromotionChoiceOptionsOrRadios();
        let shownOptions: JQuery = promotionChoiceOptions.filter(':not(.hidden)');

        if (shownOptions.is('[type="radio"]')) {
            let firstShownRadio: JQuery = shownOptions.first();
            let initialValue: boolean = firstShownRadio.prop('checked');
            firstShownRadio.prop('checked', true);
            if (initialValue !== firstShownRadio.prop('checked')) {
                firstShownRadio.change();
            }
        }
        else {
            let select: JQuery = promotionChoiceOptions.parent('select');
            let initialValue: string = select.val();
            select.val(shownOptions.first().val());
            if (initialValue !== select.val()) {
                select.change();
            }
        }

        // If there is only a single choice still visible then hide it as well
        if (shownOptions.length === 1) {
            this.togglePromotionChoiceVisibility(shownOptions, false);
        }
    }

    private getAllowedPromotionChoices(): string[] {
        let validPromotionChoicesCsv: string = this.membershipLevelSelect
            .children('option:selected')
            .data('valid-promotion-choices-csv');
        if (validPromotionChoicesCsv) {
            return validPromotionChoicesCsv.split(',');
        }
        return new Array<string>();
    }

    private getPromotionChoiceOptionsOrRadios(): JQuery {
        // Promotion choices can be represented by
        // either <option>s or [type="radio"]

        let options: JQuery = this.promotionChoicesContainer.find('option');
        if (options.length) {
            return options;
        }

        return this.promotionChoicesContainer.find('[type="radio"]');
    }
}

$(document).on('cider.shoppingCart.addToCartInitialized', (_e, container) => {
    let addToCartContainer: JQuery = $(container);
    let membershipLevelSelect: JQuery = addToCartContainer.find('select.membership-level');
    let promotionChoicesContainer: JQuery = addToCartContainer.find('div.membership-promotion-choices-container');

    if (membershipLevelSelect.length && promotionChoicesContainer.length) {
        let levelSelect: AddToCartMembershipLevelSelect = new AddToCartMembershipLevelSelect(membershipLevelSelect, promotionChoicesContainer);
        levelSelect.init();
    }
});